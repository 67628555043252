import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  images[0]["thumbAlt"] = "Click do Bem"
  images[0]["title"] = "Logo para Click do Bem"
  images[0]["caption"] = "São Paulo - Brazil"

  images[1]["thumbAlt"] = "Grass Home & Contract"
  images[1]["title"] = "Logo para Grass Home & Contract"
  images[1]["caption"] = "Barcelona - Spain"

  images[2]["thumbAlt"] = "Coffeeholics"
  images[2]["title"] = "Logo para Coffeeholics"
  images[2]["caption"] = "Barcelona - Spain"

  images[3]["thumbAlt"] = "Dr. Marco Tulio Oréfice"
  images[3]["title"] = "Logo para Dr. Marco Tulio Oréfice"
  images[3]["caption"] = "São Paulo - Brazil"

  images[4]["thumbAlt"] = "ComforttLife"
  images[4]["title"] = "Logo para ComforttLife"
  images[4]["caption"] = "São Paulo - Brazil"

  images[5]["thumbAlt"] = "Linnex Technology"
  images[5]["title"] = "Logo para Linnex Technology"
  images[5]["caption"] = "Barcelona - Spain"

  images[6]["thumbAlt"] = "Espaço Marroga"
  images[6]["title"] = "Logo para Espaço Marroga"
  images[6]["caption"] = "São Paulo - Brazil"

  images[7]["thumbAlt"] = "Amex Found Raising"
  images[7]["title"] = "Logo para Amex Found Raising"
  images[7]["caption"] = "São Paulo - Brazil"

  images[8]["thumbAlt"] = "Roma Serviços"
  images[8]["title"] = "Logo para Roma Serviços"
  images[8]["caption"] = "São Paulo - Brazil"

  images[9]["thumbAlt"] = "Thinkable Marketing"
  images[9]["title"] = "Logo para Thinkable Marketing"
  images[9]["caption"] = "Sydney - Australia"

  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }

  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | graphic design"
        description="Algumas amostras de logos criados pela NetBistrot"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-1" />
            <div className="col-span-10">
              <h1>Algumas amostras de logos que criamos</h1>
              <div className="h-8" />
              <Gallery images={images} colWidth={25} imgClass="gallery-image" />
            </div>
          </div>
        </div>
      </div>
      <div className="h-8" />
      <div className="text-center">
        <span className="text-2xl">
          Vamos trabalhar juntos?
          <br />
          <br />
        </span>
        <Link
          className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
          to="/pt/lets-work-together/?service=graphic-design"
        >
          vamos fazer um projeto de desenho gráfico
        </Link>
      </div>
      <div className="h-10" />
      <Footer lang="pt" />
    </>
  )
}

export default IndexEN

export const pageQuery = graphql`
  query ImagesForGalleryPT {
    allFile(
      filter: { relativePath: { regex: "/graphic-design/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 215
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    }
  }
`
